<template>
    <el-dialog title="审核设置" width="60%" :close-on-click-modal="false" :visible.sync="visible" class="dialogTable">
        <el-form size="small" :model="inputForm" ref="inputForm" v-loading="loading"
                 label-width="100px">
            <el-form-item label="审核模块：" prop="revdName">{{inputForm.revdName}}</el-form-item>
            <el-form-item label="启用状态：" prop="revdEnable">
                <el-switch v-model="inputForm.revdEnable" :active-value="0" :inactive-value="1"></el-switch>
            </el-form-item>
            <el-form-item label="审核步骤：" prop="list">
                <el-table :data="inputForm.list" size="small" class="table" row-key='id'>
                    <el-table-column prop="revdStep" label="审核步骤" width="120">
                        <template slot-scope="scope">
                            {{scope.$index + 1}}级
                        </template>
                    </el-table-column>
                    <el-table-column prop="stepUser" label="审核角色">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.stepUser" placeholder="请选择" filterable clearable multiple
                                       class="w100i">
                                <el-option v-for="item in userOpt" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" icon="el-icon-plus" @click="add(scope.$index)"></el-button>
                            <el-button type="text" icon="el-icon-minus" @click="minus(scope.$index)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">关闭</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>提交</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                loading: false,
                inputForm: {
                    id: '',
                    revdName: '',
                    revdEnable: 0,
                    list: [],
                },
                userOpt: [],
                activeTab: ''
            }
        },
        methods: {
            // 初始化数据
            init(row, activeTab) {
                this.activeTab = activeTab
                this.getSysRole()
                this.visible = true
                this.inputForm.id = row.id
                this.$nextTick(() => {
                    this.$refs.inputForm.resetFields();
                })
            },
            // 获取详情
            getInfo() {
                this.loading = true
                let api = this.api.record.setAuditEditInfo
                if (this.activeTab == 1 || this.activeTab == 2) {
                    api = this.api.collection.getRevdInfo
                }
                this.$axios(api + '/' + this.inputForm.id).then(data => {
                    if (data.status) {
                        this.recover(this.inputForm, data.data);
                        this.inputForm.list.map(item => {
                            this.$set(item, 'stepUser', item.stepUser.split(','))
                        })
                    }
                    this.loading = false
                })
            },
            // 加
            add() {
                if (this.inputForm.list.length < 4) {
                    this.inputForm.list.push({
                        stepUser: []
                    });
                } else {
                    this.$message.info('最多添加4级审核步骤')
                }
            },
            // 减
            minus(index) {
                if (this.inputForm.list.length > 1) {
                    this.inputForm.list.splice(index, 1);
                } else {
                    this.$message.info('最少保留1级审核步骤')
                }
            },
            // 获取用户
            getSysRole() {
                this.$axios(this.api.user.sysroleList, {
                    name: '',
                    current: 1,
                    size: 9999,
                }, 'get').then(data => {
                    if (data.status) {
                        this.userOpt = data.data.records.filter(v => v.useable == 0)
                        this.$nextTick(() => {
                            this.getInfo()
                        })
                    }
                })
            },
            // 提交
            doSubmit() {
                let num = 0
                let p = JSON.parse(JSON.stringify(this.inputForm))
                p.list.map((item, index) => {
                    item.revdStep = index + 1
                    item.stepUser = item.stepUser.join(',')
                    if (!item.stepUser) {
                        num++
                    }
                })
                this.$nextTick(() => {
                    if (num > 0) {
                        this.$message.error('请配置审核用户')
                    } else {
                        this.loading = true
                        let api = this.api.record.auditEditInfoSave
                        if (this.activeTab == 1 || this.activeTab == 2) {
                            api = this.api.collection.updateStep
                        }
                        this.$axios(api, p, 'post').then(data => {
                            if (data.status) {
                                if(this.activeTab == 1 || this.activeTab == 2){
                                    this.$message.success(data.msg)
                                }else {
                                    this.$message.success(data.data)
                                }
                                this.visible = false
                                this.$emit("refreshDataList")
                            } else {
                                this.$message.error(data.msg)
                            }
                            this.loading = false
                        })
                    }
                })

            },
        }
    }
</script>

<style scoped>

</style>
